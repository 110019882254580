@use "../../styles/theme";

.panel {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: theme.space(3);
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: theme.space(1);
  font-size: theme.$font-size-sm;
  line-height: theme.$line-height-base;
}

.cardsInfoWrapper {
  display: flex;
  margin-top: theme.space(6);
}

.heading {
  font-size: 20px;
  line-height: 24px;
}

.flex {
  display: flex;
}

.width33 {
  width: 33%;
}

.width50 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.marginTop48 {
  margin-top: theme.space(6);
}

.info {
  color: theme.$text-color-secondary;
  font-size: theme.$font-size-base;
  line-height: theme.$line-height-base;
}

.subHeader {
  margin-top: theme.space(6);
  font-size: 20px;
  line-height: 24px;
}

.count {
  margin: theme.space(1.5) 0 theme.space(0.5) 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  &.high {
    color: theme.$color-green;
  }

  &.middle {
    color: theme.$color-yellow;
  }

  &.low {
    color: theme.$color-red;
  }
}
